// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_sZ d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_s0 d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_s1 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_s2 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_s3 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_s4 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_s5 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_s6 G_s6";
export var heroExceptionNormal = "x_s7 G_s7";
export var heroExceptionLarge = "x_s8 G_s8";
export var Title1Small = "x_s9 G_s9 G_tY G_tZ";
export var Title1Normal = "x_tb G_tb G_tY G_t0";
export var Title1Large = "x_tc G_tc G_tY G_t1";
export var BodySmall = "x_td G_td G_tY G_vg";
export var BodyNormal = "x_tf G_tf G_tY G_vh";
export var BodyLarge = "x_tg G_tg G_tY G_vj";