// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "y_th d_gS d_cw d_dv";
export var quoteParagraphCenter = "y_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "y_gV d_gV d_cw d_dx";
export var quoteRowLeft = "y_tj d_bG";
export var quoteRowCenter = "y_tk d_bD";
export var quoteRowRight = "y_tl d_bH";
export var quoteWrapper = "y_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "y_gR d_gR";
export var quoteExceptionSmall = "y_tm G_tm";
export var quoteExceptionNormal = "y_tn G_tn";
export var quoteExceptionLarge = "y_tp G_tp";
export var quoteAuthorExceptionSmall = "y_tq G_tq";
export var quoteAuthorExceptionNormal = "y_tr G_tr";
export var quoteAuthorExceptionLarge = "y_ts G_ts";