// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_sp d_fp d_bG d_dv";
export var alignLeft = "v_rd d_fp d_bG d_dv";
export var alignDiscCenter = "v_sq d_fq d_bD d_dw";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignDiscRight = "v_sr d_fr d_bH d_dx";
export var alignRight = "v_rf d_fr d_bH d_dx";
export var footerContainer = "v_ss d_dW d_bW";
export var footerContainerFull = "v_st d_dT d_bW";
export var footerHeader = "v_kf d_kf";
export var footerTextWrapper = "v_sv d_w";
export var footerDisclaimerWrapper = "v_km d_km d_ck";
export var badgeWrapper = "v_sw d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "v_sx d_bz d_bJ d_bN d_bL";
export var wide = "v_sy d_cy";
export var right = "v_sz d_bK";
export var line = "v_fk d_fl d_cv";
export var badgeDisclaimer = "v_sB d_bC d_bP d_bJ";
export var badgeContainer = "v_sC d_bz d_bH d_bP";
export var badge = "v_sD";
export var padding = "v_sF d_c7";
export var end = "v_sG d_bH";
export var linkWrapper = "v_sH d_dB";
export var link = "v_mZ d_dB";
export var colWrapper = "v_sJ d_cx";
export var consent = "v_f d_f d_bC d_bP";
export var disclaimer = "v_sK d_bz d_bJ";
export var media = "v_sL d_bx d_dy";
export var itemRight = "v_sM";
export var itemLeft = "v_sN";
export var itemCenter = "v_sP";
export var exceptionWeight = "v_sQ G_vp";