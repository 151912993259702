// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "D_tN d_bD";
export var storyRowWrapper = "D_hx d_hx d_bK";
export var storyLeftWrapper = "D_tP d_bz d_bP";
export var storyWrapperFull = "D_tQ d_cD";
export var storyWrapperFullLeft = "D_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "D_mS d_hy";
export var storyLeftWrapperCenter = "D_tR d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "D_tS d_hF";
export var storyHeader = "D_tT d_hD d_w d_cs";
export var storyHeaderCenter = "D_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "D_hB d_hB d_by d_dw";
export var storyBtnWrapper = "D_tV d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "D_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "D_rj d_fg d_Z";
export var imageWrapperFull = "D_tW d_w d_H d_bf d_Z";
export var SubtitleSmall = "D_pd G_pd G_tY G_t8";
export var SubtitleNormal = "D_pf G_pf G_tY G_t9";
export var SubtitleLarge = "D_pg G_pg G_tY G_vb";
export var textLeft = "D_dv";
export var textCenter = "D_dw";
export var textRight = "D_dx";