// extracted by mini-css-extract-plugin
export var socialContainer = "C_ty";
export var instagramLink = "C_tz";
export var socialWrapperLeft = "C_qz d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "C_tB";
export var socialContentContainerFull = "C_tC";
export var instagramContainer = "C_tD";
export var twitterContainer = "C_tF";
export var facebookContainer = "C_tG";
export var socialErrorContainer = "C_tH";
export var facebookContainerWide = "C_tJ";
export var twitterContainerWide = "C_tK";
export var socialParagraphCenter = "C_tL";
export var instaWrapper = "C_tM";
export var SubtitleSmall = "C_pd G_pd G_tY G_t8";
export var SubtitleNormal = "C_pf G_pf G_tY G_t9";
export var SubtitleLarge = "C_pg G_pg G_tY G_vb";
export var textLeft = "C_dv";
export var textCenter = "C_dw";
export var textRight = "C_dx";